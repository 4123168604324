// version: 0.2

import React from 'react';
import Terminal from './Terminal';

function App() {
  return (
    <div className="App">
      <h1>Scorched Nebraska Terminal Access v0.1.1</h1>
      <Terminal />
    </div>
  );
}

export default App;

